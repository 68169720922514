import type { CartDetailsQuery } from '@/generated/shopify';

export enum shopifyHandles {
  screen = 'alter-system',
  darkScreen = 'alter-mirror-kit-dark',
  lightScreen = 'alter-mirror-kit-light',
  lightSignal = 'alter-signal-standard-light',
  darkSignal = 'alter-signal-standard-dark',
  swab = 'swab-kit',
  weights = 'dumbbell-set-dark',
  subscriptionMonthlySingle = 'alter-premium-subscription-monthly-single',
  subscriptionMonthlyHousehold = 'alter-premium-subscription-monthly-household',
  subscriptionAnnualSingle = 'alter-premium-subscription-annual-single',
  subscriptionAnnualHousehold = 'alter-premium-subscription-annual-household',
  weightBeginner = 'beginner-weights-set',
  weightHeavy = 'heavy-weight-set',
  weight5 = 'set-of-5lb-weights',
  weight10 = 'set-of-10lb-weights',
  weight15 = 'set-of-15lb-weights',
  weight25 = 'set-of-25lb-weights',
  extendProtectionPlan3YR = 'extend-protection-plans-1',
  extendProtectionPlan2YR = 'extend-protection-plans-2',
  extendProtectionPlan4YR = 'extend-protection-plans-3',
  alterAnywhereLight = 'alter-anywhere-light',
  alterAnywhereDark = 'alter-anywhere-dark',
}

export const defaultDiscountCode = 'ALTER25';

export const formatCurrency = (currencyCode?: string, amount?: number, noDecimal?: boolean) => {
  if (!currencyCode || !amount) {
    return '$';
  }
  switch (currencyCode) {
    case 'USD':
      return `$${amount.toFixed(noDecimal ? 0 : 2)}`;
    default:
      return '$';
  }
};

//--------------------------

export type ProductVariantEdge = {
  node: {
    sku: string;
    id: string;
    title?: string;
    price?: number;
  };
};

export type CartLinesEdge = {
  node: {
    id: string;
    merchandise: {
      sku: string;
    };
  };
};

// type Cart = {
//   lines: {
//     edges: [CartLinesEdge];
//   };
// };

// cartItems: [CartLinesEdge],
export const findMerchandiseFromCart = (
  cartDetailsQuery: CartDetailsQuery,
  productVariants: [ProductVariantEdge]
) => {
  if (!cartDetailsQuery?.cart) throw new Error(`UNDEFINED_CART_DETAILS_QUERY_CART`);

  const productVariantSKUs = productVariants?.map((productVariant) => productVariant.node.sku);

  const matchingItems = cartDetailsQuery.cart.lines.edges.filter((cartItem) => {
    const sku = cartItem.node.merchandise.sku;
    if (sku) {
      productVariantSKUs.includes(sku);
    }
  });

  return matchingItems;
};

export type ValueType = 'percentage' | 'fixed_amount';

export type DiscountT = {
  value_type: ValueType;
  value: string;
};

export type DiscountCodeT = {
  applicable: boolean;
  code: string;
};

export const calculateDiscount = (
  price: number,
  discount: DiscountT,
  numSplitDiscount: number = 1
) => {
  return parseFloat(
    (discount.value_type === 'percentage'
      ? price * (1 - Math.abs(parseFloat(discount.value) / numSplitDiscount) / 100)
      : price - Math.abs(parseFloat(discount.value) / numSplitDiscount)
    ).toFixed(2)
  );
};

export const parseProductId = (id: string) => {
  return id.split('/').pop();
};

//--------------------------

export const stateAbbreviations = [
  { value: 'AL' },
  { value: 'AK' },
  { value: 'AZ' },
  { value: 'AR' },
  { value: 'CA' },
  { value: 'CO' },
  { value: 'CT' },
  { value: 'DE' },
  { value: 'FL' },
  { value: 'GA' },
  { value: 'HI' },
  { value: 'ID' },
  { value: 'IL' },
  { value: 'IN' },
  { value: 'IA' },
  { value: 'KS' },
  { value: 'KY' },
  { value: 'LA' },
  { value: 'ME' },
  { value: 'MD' },
  { value: 'MA' },
  { value: 'MI' },
  { value: 'MN' },
  { value: 'MS' },
  { value: 'MO' },
  { value: 'MT' },
  { value: 'NE' },
  { value: 'NV' },
  { value: 'NH' },
  { value: 'NJ' },
  { value: 'NM' },
  { value: 'NY' },
  { value: 'NC' },
  { value: 'ND' },
  { value: 'OH' },
  { value: 'OK' },
  { value: 'OR' },
  { value: 'PA' },
  { value: 'RI' },
  { value: 'SC' },
  { value: 'SD' },
  { value: 'TN' },
  { value: 'TX' },
  { value: 'UT' },
  { value: 'VT' },
  { value: 'VA' },
  { value: 'WA' },
  { value: 'WV' },
  { value: 'WI' },
  { value: 'WY' },
];
