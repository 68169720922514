import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { getCookie } from 'cookies-next';
import { useAnimate } from 'framer-motion';
import DOMPurify from 'isomorphic-dompurify';
import type { GetServerSideProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import FiveStars from '@/components/FiveStars';
import Input from '@/components/Input';
import { sendSentryMessage } from '@/lib/sentry/sentry';
import { sendExceptionAnalytics, sendLoginAnalytics } from '@/utils/analytics';
import { getServerBaseUrl } from '@/utils/misc';
import { shopifyHandles } from '@/utils/shop';

const CUSTOM_ERROR_MESSAGES = {
  'user is blocked':
    'This account has been locked. Please contact Alter support for further assistance.',
};

type formData = {
  email: string;
  password: string;
};

type YotpoReview = {
  id: number;
  title: string;
  content: string;
  score: number;
  votes_up: number;
  votes_down: number;
  created_at: string;
  updated_at: string;
  sentiment: number;
  sku: string;
  name: string;
  email: string;
  reviewer_type: string;
  deleted: boolean;
  archived: boolean;
  escalated: boolean;
  is_incentivized: boolean;
};

export const getServerSideProps: GetServerSideProps = async ({ query, req, res }) => {
  const auth = getCookie('auth', { req, res });
  console.log(req.headers.referrer);
  const destination = (): string => {
    if (query?.referrer === 'system') return `/shop/${shopifyHandles.screen}`;
    if (query?.setup === 'true') return `/register/onboarding`;
    return `/account`;
  };
  if (auth) {
    return {
      redirect: {
        permanent: false,
        destination: destination(),
      },
    };
  }

  // get yotpo reviews
  const response = await fetch(getServerBaseUrl(req) + '/api/yotpo/', {
    method: 'GET',
  });
  const data = await response.json();
  const filteredReviews =
    data?.reviews
      ?.filter?.((review: YotpoReview) => review?.score === 5)
      ?.slice?.(0, 5)
      ?.sort(() => 0.5 - Math.random()) ?? [];

  return {
    props: {
      test: query,
      reviews: filteredReviews ?? [],
    },
  };
};

interface LoginProps {
  test: any;
  reviews: YotpoReview[];
}

const ANIMATION_DURATION = 0.5;

const Login = ({ test, reviews }: LoginProps) => {
  const router = useRouter();
  const { query } = router;
  const searchParams = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [reviewIndex, setReviewIndex] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const [scope, animate] = useAnimate();

  const prevReview = () => {
    animate(scope.current, { opacity: 0, x: '100%' }, { duration: ANIMATION_DURATION }).then(() => {
      setReviewIndex((prev) => (prev - 1 + reviews.length) % reviews.length);
      setShowMore(false);
      animate(scope.current, { opacity: 1, x: ['-100%', '0%'] }, { duration: ANIMATION_DURATION });
    });
  };

  const nextReview = () => {
    animate(scope.current, { opacity: 0, x: '-100%' }, { duration: ANIMATION_DURATION }).then(
      () => {
        setReviewIndex((prev) => (prev + 1) % reviews.length);
        setShowMore(false);
        animate(scope.current, { opacity: 1, x: ['100%', '0%'] }, { duration: ANIMATION_DURATION });
      }
    );
  };

  const toggleShowMore = () => setShowMore((prev) => !prev);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<formData>({});

  const onSubmit = async (data: formData) => {
    const req_body = {
      username: data?.email,
      password: data?.password,
    };
    setLoading(true);
    const formBody: string[] = [];
    for (const property in req_body) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(req_body[property as keyof typeof req_body]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    const form_str = formBody.join('&');
    const res = await fetch('/api/auth/login/', {
      method: 'POST',
      body: form_str,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        accept: 'application/json',
      },
    });
    console.log('res: ', res);
    if (res.ok) {
      sendLoginAnalytics(data?.email);
      localStorage.removeItem('kitId');
      localStorage.removeItem('activation_code');
      setLoading(false);
      const from = searchParams.get('from') ?? '/account';
      const [fromPath, search] = from.split('?');
      const path =
        query?.referrer === 'system'
          ? `/shop/${shopifyHandles.screen}`
          : query?.setup === 'true'
            ? `/register/onboarding`
            : fromPath;
      return router.push({
        pathname: path,
        search: search ?? '',
      });
    } else {
      setLoading(false);
      sendExceptionAnalytics('login error');
      sendSentryMessage('login error');
      const response = await res.json();
      const errorMessage =
        CUSTOM_ERROR_MESSAGES[response?.error_description as keyof typeof CUSTOM_ERROR_MESSAGES] ??
        response?.error_description;
      setError('password', { message: errorMessage });
    }
  };

  return (
    <div className="bg-white min-h-screen flex flex-col text-center lg:flex-row">
      {/* login form */}
      <div className="basis-1/2 z-10">
        <form
          className="relative max-w-[560px] h-full flex flex-col justify-center mx-auto p-5 pb-14 lg:p-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Link href="/" className="mx-auto lg:absolute lg:top-6 lg:left-2">
            <Image src="/icons/logo.svg" alt="Alter" height={32} width={32} />
          </Link>
          <div className="grow mt-8 lg:grow-0 lg:mt-0">
            <h1 className="font-light text-[32px]">Log In</h1>
            <div className="flex flex-col gap-[16px] mt-6 break-words lg:mt-10">
              <Input
                {...register('email', { required: true })}
                placeholder="Email Address"
                inputStyle="rounded"
              />
              {errors.email && errors.email.type === 'required' && (
                <div className="error" role="alert">
                  Email Address is required
                </div>
              )}

              <div className="relative">
                <Input
                  {...register('password', { required: true })}
                  inputStyle="rounded"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                />
                <button type="button" onClick={() => setShowPassword((prev) => !prev)}>
                  <Image
                    src={showPassword ? '/icons/eye-closed.svg' : '/icons/eye-open.svg'}
                    alt="show password"
                    height={15}
                    width={20}
                    className="absolute right-4 top-[36%] -translate-y-1/2"
                  />
                </button>
              </div>
              {errors.password && errors.password.type === 'required' && (
                <div className="error" role="alert">
                  Password is required
                </div>
              )}

              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <div className="error">{message}</div>}
              />
            </div>
          </div>
          <div className="flex flex-col gap-[16px]">
            <button type="submit" className="button w-full mt-6 mb-2 font-light text-lg">
              {loading ? 'Loading' : 'Log in'}
            </button>
            <div className="text-sm text-alter-black-80">
              <Link href={'/account/settings/reset_password'}>Forgot your password?</Link>
            </div>
            {Object.prototype.hasOwnProperty.call(query, 'setup') && (
              <div className="text-sm text-alter-black-80">
                New? <Link href={'/register/account'}>Register an account</Link>
              </div>
            )}
          </div>

          <span className="mt-6 font-light text-sm lg:absolute lg:left-2 lg:bottom-6">
            © 2025 FIT Wind, LLC.
          </span>
          <div className="mt-2 space-x-6 font-light text-sm lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:bottom-6">
            <Link href={'/legal/privacy-policy'} className="text-alter-black-80">
              Privacy Policy
            </Link>
            <Link href={'/legal/terms-of-use'} className="text-alter-black-80">
              Terms of Use
            </Link>
          </div>
        </form>
      </div>
      {/* Reviews */}
      <div className="relative flex flex-col items-center justify-center max-w-[1000px] px-5 py-14 bg-[url('/login/gradient.webp')] bg-cover overflow-hidden lg:basis-1/2 lg:p-10">
        <div
          ref={scope}
          className="flex flex-col flex-grow justify-start h-full max-w-[560px] mx-auto space-y-8 lg:justify-center"
        >
          <FiveStars className="justify-center" />
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(reviews?.[reviewIndex]?.content ?? ''),
              }}
              className={clsx(
                'font-light text-xl lg:text-2xl lg:line-clamp-[11]',
                !showMore && 'line-clamp-6'
              )}
            />
            <button className="inline-block ml-2 lg:hidden" onClick={toggleShowMore}>
              show {showMore ? 'less' : 'more'}
            </button>
          </div>
          <div className="text-lg">{reviews?.length && reviews?.[reviewIndex]?.name}</div>
        </div>
        {/* Pagination buttons */}
        <div className="flex items-center gap-12 z-10 justify-self-end mt-6 lg:mt-auto">
          <button
            className="flex justify-center items-center h-12 w-12 rounded-full bg-alter-bone-50"
            type="button"
            onClick={prevReview}
          >
            <Image
              src={'/icons/arrow-small-right.svg'}
              alt="previous"
              height={24}
              width={24}
              className="rotate-180"
            />
          </button>
          <div className="flex gap-2">
            {reviews?.map((_, idx) => (
              <span
                key={idx}
                className={`h-2 w-2 rounded-full ${reviewIndex === idx ? 'bg-alter-black' : 'bg-alter-bone'}`}
              />
            ))}
          </div>
          <button
            className="flex justify-center items-center h-12 w-12 rounded-full bg-alter-bone-50"
            type="button"
            onClick={nextReview}
          >
            <Image src={'/icons/arrow-small-right.svg'} alt="next" height={24} width={24} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
